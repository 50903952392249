

<template>

    <div class="grid grid-cols-12 gap-4" v-if="item.source == type" style="border-bottom: 0.5px solid #000000">
        <div>
            <img :src="wineImage(item)" class="oeno_portfolio_wineimage" onerror="this.style.display='none'" />
        </div>
        <div class="col-span-5 mt-8">
            <span class="oeno_portfolio_winetitle_buy">{{
                item.name
            }}</span><br />
            <span class="oeno_portfolio_winesubtitle_buy" v-html="winary(item)"></span><br />
        </div>
        <div class=" mt-8">
            <span class="oeno_portfolio_winetitle_buy">{{
                getQuantity(item)
            }}</span><br/>
            <span class="oeno_portfolio_winebond_buy" v-html="getBond(item)"></span>            
        </div>
        <div v-if="item.sell_size=='bottle'" class="col-span-2 mt-8">

            <span class="float-right oeno_portfolio_price ">GBP&nbsp;{{ currencyFormatter(item.sell_price,true) }}</span>


        </div>
        <div v-if="item.sell_size=='bottle'" class="col-span-2 mt-12">

            <button
                class="w-32 h-10 oeno_button_black float-right"
                @click="buywineretail(item.wine_id, item.source, item.sell_size)">Buy</button>

        </div>
        <div v-if="item.sell_size=='case'" class="col-span-2 mt-8">
            <span class="float-right oeno_portfolio_price ">GBP{{
                currencyFormatter(item.min_price,true)
            }}<span v-if="item.min_price != item.max_price">&nbsp;to&nbsp;{{ currencyFormatter(item.max_price,true) }}</span></span>

        </div>
        <div v-if="item.sell_size=='case'" class="col-span-2 mt-12">
            <button
                class="w-32 h-10 oeno_button_black float-right"
                @click="buywine(item.wine_id, item.source, item.sell_size)">Buy</button>

        </div>

    </div>
</template>
   
<script>


export default {
    name: 'BuyWineSummary',
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            type: String,
            default() {
                return 'retail'
            }
        }
    },
    methods: {

        wineImage(wine) {
            var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;
            if (wine.image != '') {
                wineimageurl = process.env.VUE_APP_WINE_IMAGE_PATH + wine.image;
            }
            return wineimageurl;
        },
        getQuantity(wine) {

            var plural = "";
            if (wine.available > 1) {
                plural = "s"
            }
            return wine.available + ' ' + wine.sell_size + plural;
        },
        getBond(wine) {

            if (wine.sell_size == 'bottle' ) {
                return "<small>(" + wine.market + ")</small>"
            } else {
                return "In Bond" + "<br/><small>(" + wine.market + ")</small>"

            }
        },
        winary(wine) {
            var winary = wine.winery + " " + wine.vintage;
            if (wine.country != "") {
                winary = winary + "<br/>" + wine.country;
            }
            if (wine.size != "") {
                winary = winary + "<br/>" + wine.size + "ML";
            }
            return winary;
        },

        buywine(id, source, sell_size) {
            this.$router.push({
                name: 'page--buy--stock', params: { id: id, source: source, sell_size: sell_size }
            });
        },

        buywineretail(id, source, sell_size) {
            this.$router.push({
                name: 'page--buy--oh', params: { id: id, source: source, sell_size: sell_size }
            });
        },

    }
}
</script>
