<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>
    <secondary-navbar
      title="Wines To Consume"
      :showbroker="false"
      :showaction="false"
      :showback="false"
      :showportfolio="true"
      v-if="$route.params.type == 'oenohouse'"
    />

    <secondary-navbar
      title="Wine Investment"
      :showbroker="false"
      :showaction="false"
      :showback="false"
      :showportfolio="true"
      v-if="$route.params.type == 'future'"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip :showBrokerButton="true" :showPie="false" />

        <div v-if="stock" class="m-10">
          <div class="relative text-black focus-within:text-black mb-5">
            <div class="float-left">
              <button
                class="w-32 h-10 oeno_button_white"
                @click="resetSearch()"
              >
                CLEAR FILTERS
              </button>
            </div>

            <div class="float-left pt-10 w-full">
              <span class="absolute inset-y-15 left-0 flex items-center pl-2">
                <button
                  type="submit"
                  class="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    class="w-6 h-6"
                  >
                    <path
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </button>
              </span>
              <input
                type="text"
                v-model="search"
                name="q"
                class="py-2 text-sm text-black bg-white rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900 border border-black w-full"
                placeholder="Search..."
                autocomplete="off"
                @keyup="pageNumber = 1"
              />
            </div>
          </div>

          <div class="mb-10 float-left mt-10">

            <label class="oeno_portfolio_sub_title">Vintage &#x25BC;</label><br/>
            <md-radio
                v-for="item in vintages"
                v-bind:key="item"
                v-model="selectedVintages"
                :value="item"
                class="filter-radio-item  pl-5 float-left"
                >{{ item }}</md-radio
            ><br/>

            <label class="oeno_portfolio_sub_title mt-5 float-left">Bottle Size &#x25BC;</label><br/>
            <md-radio
                v-for="item in sizes"
                v-bind:key="item"
                v-model="selectedSizes"
                :value="item"
                class="filter-radio-item pl-5 float-left"
                >{{ item }}ML</md-radio
            ><br/>


            <label class="oeno_portfolio_sub_title mt-5 float-left">Winery &#x25BC;</label><br/>
            <md-radio
                v-for="item in winaries"
                v-bind:key="item"
                v-model="selectedWinaries"
                :value="item"
                class="filter-radio-item pl-5 float-left"
                >{{ item }}</md-radio
            ><br/>

            <label class="oeno_portfolio_sub_title mt-5 float-left">Country &#x25BC;</label><br/>
            <md-radio
                v-for="item in countries"
                v-bind:key="item"
                v-model="selectedCountries"
                :value="item"
                class="filter-radio-item pl-5 float-left"
                >{{ item }}</md-radio
            ><br/>

          </div>
        </div>
      </div>

      <div class="col-span-8">
        <span id="download_loader" v-if="!stock"></span>
        <div v-if="stock">

                <div
                  style="border-bottom: 0.5px solid #000000"
                  class="grid grid-cols-12 pb-2"
                >
                  <p class="oeno_col_heading col-span-6 ml-10">Name</p>

                  <p class="oeno_col_heading col-span-2">Quantity</p>

                  <p class="float-right oeno_col_heading col-span-2">
                    Price
                  </p>

                  <p class="oeno_col_heading"></p>
                </div>

          <div v-for="item in paginatedData" v-bind:key="item.id">
            <div v-if="notOwnWine(item)">
              <wine-summary :item="item" :type="$store.state.marketData" />
            </div>
          </div>

          <div class="grid place-items-center mt-10">
            <t-pagination
              :total-items="filteredData.length"
              :per-page="size"
              :disabled="false"
              variant="rounded"
              v-model="pageNumber"
            />
          </div>

          <br/><br/><br/><br/><br/><br/>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";
import WineSummary from "../../../components/buy/WineSummary.vue";

export default {
  data() {
    return {
      stock: [],
      pageNumber: 1,
      size: 10,
      search: "",
      selectedVintages: "",
      vintages: [],
      selectedSizes: "",
      sizes: [],
      selectedWinaries: "",
      winaries: [],
      selectedCountries: "",
      countries: [],
    };
  },
  components: {
    WineSummary,
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },

  mounted() {
    this.$nextTick(() => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.get("/api/get-stock", { headers: headers })
        .then((response) => {
          if (response.data.statuscode == 200) {
            this.stock = response.data.data.stock;
            this.populateDropDownData();
          }
        })
        .catch((response) => {
          if (response.response.status == 401) {
            this.$store.dispatch("login", { token: "" });
            this.$router.push("/login");
            this.$store.dispatch("storePortfolioData", {});
          }
        });
    });
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
    pageCount() {
      let l = this.stock.length,
        s = this.size;
      return Math.ceil(l / s);
    },
    filteredData() {
      let filteredStock = [];

      this.stock.forEach((stock) => {
        var matched = false;
        var searchapplied = false;

        if (this.search != "") {
          searchapplied = true;

          if (stock.name.toLowerCase().includes(this.search.toLowerCase())) {
            matched = true;
          }
          if (stock.vintage.toLowerCase().includes(this.search.toLowerCase())) {
            matched = true;
          }
          if (stock.winery.toLowerCase().includes(this.search.toLowerCase())) {
            matched = true;
          }
          if (
            stock.size
              .toLowerCase()
              .includes(this.search.toLowerCase().replace("ml", ""))
          ) {
            matched = true;
          }
        }

        if (this.selectedSizes.length != "") {
          searchapplied = true;
          if (this.selectedSizes == stock.size) {
            matched = true;
          }
        }

        if (this.selectedVintages != "") {
          searchapplied = true;
          if (this.selectedVintages == stock.vintage) {
            matched = true;
          }
        }

        if (this.selectedWinaries != "") {
          searchapplied = true;
          if (this.selectedWinaries == stock.winery) {
            matched = true;
          }
        }

        if (this.selectedCountries != "") {
          searchapplied = true;
          if (this.selectedCountries == stock.country) {
            matched = true;
          }
        }

        if (!searchapplied) {
          matched = true;
        }

        if (this.$store.state.marketData != stock.source) {
          matched = false;
        }

        if (matched) {
          filteredStock.push(stock);
        }
      });

      return filteredStock;
    },
    paginatedData() {
      let pagenumber = this.pageNumber;
      if (
        (this.pageNumber - 1) * this.size + this.size >
        this.filteredData.length
      ) {
        pagenumber = 1;
      }

      const start = (pagenumber - 1) * this.size,
        end = start + this.size;

      return this.filteredData.slice(start, end);
    },
  },
  methods: {
    notOwnWine(item) {
      if (item.crm_client_ref == this.$store.state.user.unique_number) {
        return false;
      } else {
        return true;
      }
    },
    populateDropDownData() {
      let vintages = [];
      let sizes = [];
      let winaries = [];
      let countries = [];

      this.stock.forEach((stock) => {
        if (this.$store.state.marketData == stock.source) {
          // Its  valid record so save the lookup data
          if (vintages.indexOf(parseInt(stock.vintage)) === -1) {
            vintages.push(parseInt(stock.vintage));
          }
          if (sizes.indexOf(parseInt(stock.size)) === -1) {
            sizes.push(parseInt(stock.size));
          }
          if (winaries.indexOf(stock.winery) === -1) {
            winaries.push(stock.winery);
          }
          if (countries.indexOf(stock.country) === -1) {
            countries.push(stock.country);
          }
        }
      });

      this.vintages = vintages.sort(function (a, b) {
        return a - b;
      });
      this.sizes = sizes.sort(function (a, b) {
        return a - b;
      });
      this.winaries = winaries.sort();
      this.countries = countries.sort();
    },
    resetSearch() {
      this.selectedSizes = [];
      this.selectedVintages = [];
      this.selectedWinaries = [];
      this.selectedCountries = [];
      this.search = "";
    },
  },
};
</script>
